import React, { memo } from 'react'
import { styled } from '@mui/material'

interface Props {
  children: React.ReactNode
}

const Border = styled('div')({
  overflow: 'hidden',
  backgroundColor: 'white',
  position: 'relative',
  borderRadius: '50%',
  border: '4px solid white',
})
const Frame = styled('div')({
  height: 0,
  paddingTop: '100%',
  position: 'relative',
})

const Inner = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  img: {
    borderRadius: '50%',
  },
})

const RoundFrame = ({ children }: Props) => (
  <Border>
    <Frame>
      <Inner>{children}</Inner>
    </Frame>
  </Border>
)

export default memo(RoundFrame)
