import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Container, Divider, styled } from '@mui/material'

import { LocalPostOffice } from '@mui/icons-material'
import { FaLinkedinIn } from 'react-icons/fa'

import ExternalButtonLink from '_core/components/atoms/buttons/ExternalButtonLink'
import Flex from '_core/components/atoms/layout/Flex'
import RoundFrame from '_core/components/atoms/media/RoundFrame'
import Section from '_core/components/atoms/layout/Section'

import Layout from 'public/components/organisms/global/Layout'
import PageTitleHero from 'public/components/organisms/hero/PageTitleHero'
import Mandates from 'public/components/organisms/sections/Mandates'
import BlogPostScroll from 'public/components/organisms/sections/BlogPostScroll'
import HeadingDivider from 'public/components/atoms/dividers/HeadingDivider'

interface Props {
  data: Queries.TeamDetailQuery
}

const ImageWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  right: '50%',
  transform: 'translate3d(-50%, -50%, 0)',
  zIndex: 10,
}))

const StyledImage = styled(GatsbyImage)({
  width: '100%',
  height: '100%',
})

const TeamDetail = ({ data }: Props) => {
  const member = data.datoCmsTeamMember
  const mandates = data.allDatoCmsPageMandate.edges
  const blogPosts = data.allDatoCmsBlogPost.edges

  const border = 4 * 2
  const memberImageSizes = {
    xs: 200,
    sm: 220,
    md: 250,
    lg: 260,
    xl: 276,
  }
  let paddingTops = {}
  let memberImageWidths = {}

  Object.keys(memberImageSizes).map((key) => {
    const value: number = memberImageSizes[key as keyof typeof memberImageSizes]
    paddingTops[key] = (value + border) / 2 + 25 + 'px'
    memberImageWidths[key] = value + 'px'
  })

  return (
    <Layout
      metadata={{
        seoTags: member.seoMetaTags,
        extraTitle: member.jobTitle,
        customDescription: member.bio.substring(0, 200),
        customImage: {
          src: member.metaImage.gatsbyImageData.images.fallback.src,
          width: 1200,
          height: 630,
        },
      }}
    >
      <PageTitleHero
        mainHeading={member.name}
        smallHeading="Executive Search"
        imageLandscape={member.imageLandscape}
        imagePortrait={member.imagePortrait}
      />
      <Box position="relative">
        <ImageWrapper width={memberImageWidths} mx="auto">
          <RoundFrame>
            <StyledImage
              image={member.profileImage.gatsbyImageData}
              alt={`Picture of ${member.name}`}
            />
          </RoundFrame>
        </ImageWrapper>
        <Box position="relative">
          <Section vSpace sx={{ paddingTop: '0 !important' }}>
            <Container maxWidth="md">
              <Box
                typography="h3"
                mx="auto"
                pt={paddingTops}
                textAlign="center"
              >
                {/* {member.name} */}
                {member.jobTitle}
              </Box>
              {/* <Text textAlign="center" mt={1}>
                {member.jobTitle}
              </Text> */}
              <HeadingDivider sx={{ mt: 3, mb: 5, mx: 'auto' }} />
              <Box
                className="large-text"
                dangerouslySetInnerHTML={{
                  __html: member.bioNode.childMarkdownRemark.html,
                }}
              />
              <Divider sx={{ mt: 5, mb: 4 }} />

              <Flex alignCenter>
                <Box mr={1}>
                  <ExternalButtonLink
                    href={member.linkedinProfileUrl}
                    buttonProps={{
                      variant: 'outlined',
                      sizeVariant: 'xl',
                      colorVariant: 'outline-dark',
                      strongLabel: true,
                      darkLabel: true,
                      startIcon: <FaLinkedinIn />,
                    }}
                  >
                    LinkedIn
                  </ExternalButtonLink>
                </Box>
                <ExternalButtonLink
                  href={`mailto:${member.emailAddress}`}
                  buttonProps={{
                    variant: 'outlined',
                    sizeVariant: 'xl',
                    colorVariant: 'outline-dark',
                    strongLabel: true,
                    darkLabel: true,
                    startIcon: <LocalPostOffice />,
                  }}
                >
                  Contact
                </ExternalButtonLink>
              </Flex>
            </Container>
          </Section>
        </Box>
      </Box>

      {mandates.length >= 4 && (
        <Mandates
          mandates={mandates}
          heading={`${member.name}'s Mandates`}
          buttonSlug="mandates"
          buttonLabel="View All Mandates"
        />
      )}
      {blogPosts.length > 5 && (
        <BlogPostScroll
          posts={blogPosts}
          heading={`Blog Posts by ${member.name}`}
          wrapperBoxProps={{
            bgcolor: mandates.length < 4 ? 'lightGrey' : 'common.white',
          }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query TeamDetail($slug: String!) {
    datoCmsTeamMember(slug: { eq: $slug }) {
      slug
      jobTitle
      linkedinProfileUrl
      emailAddress
      name
      bio
      bioNode {
        childMarkdownRemark {
          html
        }
      }
      imageLandscape: backgroundImage {
        gatsbyImageData(
          width: 1600
          layout: FULL_WIDTH
          imgixParams: {
            ar: "16:5"
            fit: "crop"
            crop: "focalpoint"
            sat: -100
          }
        )
      }
      imagePortrait: backgroundImage {
        gatsbyImageData(
          width: 900
          layout: FULL_WIDTH
          imgixParams: { ar: "3:4", fit: "crop", crop: "focalpoint", sat: -100 }
        )
      }
      profileImage {
        gatsbyImageData(
          width: 300
          layout: CONSTRAINED
          imgixParams: { ar: "1:1", fit: "crop", crop: "focalpoint", sat: -100 }
        )
      }
      metaImage: profileImage {
        gatsbyImageData(
          layout: FIXED
          imgixParams: { w: "1200", h: "630", fit: "crop", crop: "focalpoint" }
        )
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsPageMandate(
      filter: { consultants: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [active, meta___createdAt], order: [DESC, DESC] }
      limit: 12
    ) {
      edges {
        node {
          ...mandateCard
        }
      }
    }
    allDatoCmsBlogPost(
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
      limit: 6
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          ...BlogPostCard
        }
      }
    }
  }
`

export default TeamDetail
